jQuery(document).ready(function($) {
	$(".conference-heading.tabbed").click(function() {
		if ($(this).attr("data-tab").length) {
			$(".conference-heading.tabbed").removeClass("current");
			$(this).addClass("current");
			$(".tab-pane").removeClass("tab-active");
			$(".tab-pane[data-tab='" + $(this).attr("data-tab") + "']").addClass("tab-active");
		}
	});
});
